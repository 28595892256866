import React,{useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {Fetch} from './Api'

const ColorsOptions = ({entity, item, colors, defaultValue, onChange, readOnly=false, title}) => {
    const id=`Color-${entity}-${item.Id}` 
    const [value, Value] = useState(defaultValue??1)

    useEffect(()=>{
        const color = colors.find(i=>i.Id===value)
        //  alert(`id=${id} value=${value}`)
        if(color) {
            const parent = document.getElementById(id)
            parent.style.color = `rgba(${color.Font})`
            parent.style.backgroundColor = `rgba(${color.Back})` // `rgba(${"217,210,233,0.5"})`;
            color.Bord ?? (parent.style.borderColor = `rgba(${color.Bord})`)
        }    

    },[item?.Id, value])

    const onSelect=(e) =>{
        if(e?.target) {
            onChange && onChange(e)
            if(!readOnly && item?.Id>0 && e.target.value>0 && value!==e.target.value) {
                Value(e.target.value)
                //  alert(`onSelect ${member},${planned} : ${e.target.name} value=${e.target.value} default=${value}`)
                //Fetch('/Evaluation',{id:item.Id,value:e.target.value},3,null,Message,null,Status) 
                //alert(`Fetch ${status}`)
                
                const child = document.getElementById(`${id}-${e.target.value}`);
                if(child) {
                    //alert(`${child.id}`) 
                    e.target.style.color = child.style.color
                    e.target.style.backgroundColor = child.style.backgroundColor // `rgba(${"217,210,233,0.5"})`
                    e.target.style.borderColor = child.style.borderColor
                }
            }
        }
    }
 
    return (

            <Form.Control as="select" id={id} key={id} className="text-right" 
                onChange={e => onSelect(e)} 
                defaultValue={value} 
                title={title}
                readOnly={readOnly} disabled={readOnly} 
                style={{fontSize:'1rem', height:'2rem'}}>
                {
                    colors.map(color => 
                    <option id={`${id}-${color.Id}`} key={`${id}-${color.Id}`} value={color.Id}
                    selected={value===color.Id} 
                    style={{backgroundColor:`rgba(${color.Back})`, color:`rgba(${color.Font})`}}
                    >{color.Name}</option>
                    )
                }
            </Form.Control> 
        
    )
}

const ColorsContextMenu = ({colors, onSelect, label = "Colors"}) => {
 
    const OnSelect=(event, item) =>{
        //console.log(item); alert(contextMenu.title)
        onSelect && onSelect(label, item)
    }
 
    return (
        colors?.length>0 ? 
    <li class="menu-item" onClick={(e)=>OnSelect(e)}>
        <span class="menu-text">{label}</span>
        <ul class="sub-menu" title={label} >
        {   colors.map(color => 
            <li id={`C${color.Id}`} class="menu-item" title={`${color.Back}-${color.Font}`} onClick={(e)=>OnSelect(e,color)}
                style={{backgroundColor:`rgba(${color.Back})`, color:`rgba(${color.Font})`}} value={color.Id}>
             C </li>   
        )}

        </ul>
    </li>
        
        : <div>{label} loading...</div> 
    )
}


export {ColorsContextMenu, ColorsOptions}