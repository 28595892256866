import React,{useState} from 'react'
import {FormControl} from 'react-bootstrap';

const DefaultControl = ({message}) => {
    return (
        <div className="Title" style={{width:'100%', margin:'12rem auto'}}>
        <h1>moshaf.akademy.ma</h1>
        <h2>قم بتسجيل الدخول للمتابعة</h2>
        <h3>Log In to continue</h3>
        </div>
    )
}

const SearchControl = ({onSearch}) => {
    //const searched = useRef("") 
    const [showing, Showing] = useState(false)

    const onChange = (e) => {
        onSearch(e.target.value)
    }

    const onClick = (e) => {
        if(showing)
            onSearch("")
            
        Showing(!showing)
    }

    return (
        <div style={{textAlign: 'center', float: 'left', fontSize: '1.2rem'}}>
                        
        <i className="fa fa-search" onClick={onClick} ></i>

        { showing ?       
            <FormControl 
                className="d-inline" 
                style={{ backgroundColor:'lightblue', width:"6rem", marginLeft:"0.5rem" }} 
                name="Search" type="text" 
                defaultValue=""
                onChange={onChange}
                placeholder="search" />
        : null }

        </div>
    )
}

export {SearchControl, DefaultControl}
