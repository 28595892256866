import React, {useState, useRef, useEffect, useCallback} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Header from './components/Home/Header';
import Footer from './components/Home/Footer';
import {Player} from './components/Shared/ViewAudio';
import Sentences from './components/Sentence/Sentences';
import Settings from './components/Setting/Settings';
import Profile from './components/Profile/Profile';

function App() {
  const app = "Akademy"
  const logo = 'logo.png'
  const url ="https://moshaf.akademy.ma/waqafaat/001.mp3"

  const route = useRef("/")

  const [search, Search]  = useState("")
  const [etat, Etat]  = useState("")
  const [connected, Connect] = useState(false)
  const [role, Role] = useState(2)
  const [infos, Infos] = useState([])
  const [layout, Layout] = useState(window.matchMedia('(orientation:landscape)').matches)

  useEffect(() => {
      const store = localStorage.getItem(app)
      //console.log('localStorage',store)
      if(store) {
          const value = JSON.parse(store)
          Connect(value?.connected)
          Infos(value?.message?.split('-'))
      }
  },[])


  return (

    <BrowserRouter>

    <Header app={app} onSearch={Search} logo={logo} connected={connected} infos={infos} />

{ connected ?
  <Routes>    
      <Route path='/' element={ <Home search={search} connected={connected} Etat={Etat} />} exact />
      <Route path='/sentence' element={ <Sentences props={{connected, search, layout}} />} />
      <Route path='/setting' element={ <Settings connected={connected} role={role} search={search}  layout={layout}/>} />
      <Route path='/profile' element={ <Profile connected={connected} />} />
      <Route path='/audio' element={ <Player connected={connected} />} />
  </Routes>
:      
  <Home search={search} connected={connected} Etat={Etat} />
}
    <Footer etat={etat} connected={connected} />

    </BrowserRouter>

  )

}

export default App;
