import React, {useState,useEffect, useRef} from 'react'
import { DefaultControl } from './ViewControl'


const useAudio =  url => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
  
    const toggle = () => setPlaying(!playing);
  
    useEffect(() => {
        playing ? audio.play() : audio.pause();
      },
      [playing]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  
    return [playing, toggle];
  };
  
  
  const Player = ({connected}) => {
    const [file, File]= useState("https://moshaf.akademy.ma/waqafaat/001/001.mp3")

    const [playing, toggle] = useAudio(file);
  
    return (
        connected ?
      <div>
        <button onClick={toggle}>{playing ? "Pause" : "Play"}</button>
      </div>
      : <DefaultControl/>
    )
  }
  
export {Player}