import React, {useState, useEffect} from 'react'
import {Row, Col, Button} from 'react-bootstrap'
import {SearchControl} from '../Shared/ViewControl'
import {Navigation} from '../Home/Navigation'
import SignModal from '../Sign/SignModal'

const Header = ({app, logo, onSearch, layout, connected=false, infos=[]}) => {
    const [showin, Showin] = useState(false)
    const [profile, Profile] = useState('hidden')
  
    const onLogin = (e) => {
        //alert("Login from akademy.ma...")
        Showin(true)
    }

    const onProfile = (e) => {
        //alert("onProfile")
        if(profile==='hidden') {
            const element = document.getElementById("ProfileItems")
            if(element) {
                //element.style.top = `${e.clientY+24}px`
                element.style.left = `${e.clientX}px`
            }
        }
        Profile( profile==='hidden' ? 'visible' : 'hidden')
    }

    const onLogout = (e) => {
        if(connected) {
            localStorage.removeItem(app)
            window.location.reload()
        }
    }
  

    const bstyle = { width: '6rem', display: 'block', margin:'0.2rem', fontSize:'1.2rem' }  

    return (
        
        <div className="Header" id="Header">
            <Col width="100px">
                <a href="/">
                    <img className="Logo" src={logo} alt="Home" />
                </a>
            </Col>

            <Col>            
            <Row className="navbar navbar-custom" expand="lg">
            <Col sm={6} style={{width:'50%'}}>
                <Navigation connected={connected} />
            </Col>            
            <Col sm={4}>
                { connected ?
                <SearchControl onSearch={onSearch} />
                : 
                null
                }
            </Col>
            <Col sm={2}>
                { connected ?
                    <Button style={bstyle} variant="outline-help" 
                    onClick={onProfile}
                    >{infos.length>0 ? infos[0] : "Salaam ?"}</Button>
                :
                    <Button style={bstyle} variant="outline-primary"
                        onClick={onLogin}>
                        <i className="fa fa-sign-in"> Login</i>
                    </Button>

                }
            </Col>             

            </Row>
            </Col>

{ showin ?
<SignModal 
    app={app}
    show={showin}
    logo={logo}
    onHide={()=>{Showin(false)}}
/> 
: null }

    <ul id="ProfileItems" style={{visibility:profile}}>
		<li id="ProfileName">{ connected && infos.length>1 ?infos[1]: ''}</li>
		<li><a href="/profile">Profile</a></li>
        <li><a href="/audio">Audio</a></li>
		<li><a href="https://akademy.ma" target="_blank">Akademy</a></li>
        <li><a href="https://digitalservices.ma/apps/AkademyApp/publish.htm" target="_blank">AkademyApp</a></li>
        <li><a href="https://moshaf.akademy.ma/wajh/?course=5" target="_blank">Wajh</a></li>
        <li><a href="https://moshaf.akademy.ma/waqafaat/?soora=1" target="_blank">Waqafaat</a></li>
        <li><a href="https://moshaf.akademy.ma/aayaat" target="_blank">Aayaat</a></li>
        <li><a href="https://moshaf.akademy.ma/tilaawa" target="_blank">تلاوة</a></li>
        <li><a href="https://moshaf.akademy.ma/all" target="_blank">بحث</a></li>
        <li><a href="https://moshaf.akademy.ma/aya" target="_blank">All</a></li>
        <li><a href="https://moshaf.akademy.ma/tadabbor" target="_blank">تدبر</a></li>
        <li><a href="/setting">Settings</a></li>
		<li onClick={onLogout} >Sign Out</li> 
	</ul>

</div>
                
    )
}

export default Header;